<template>
    <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

    <div id="maincontent_container">
        <company-submenu></company-submenu>
        <div class="submenu-content">
            <p class="normal">Content of the Web site is for informational purposes only.  Contact your sales representatives at (408)  for verification of prices, specials, dates and details of promotions.  </p>
            <p class="normal">Certain links will allow you to leave the Ma Labs website.  The linked sites are not under the control of Ma Labs and Ma Labs is not responsible for the contents of any linked site or any link in a linked site, or any changes or updates thereto.  Ma Labs is not responsible for any Webcasts or other form of transmission received from any linked site.  Ma Labs provides such links strictly as a convenience, and the inclusion of any linked site shall not be considered an endorsement of the site or its content by Ma Labs. </p>
            <p class="normal">Every effort has been made to ensure the accuracy of all information on this Web site.  Ma Labs makes no warranty, express or implied, with respect to the accuracy of the information, including prices, product editorials, product specifications or advertising.</p>
            <p class="normal">All manufacturers' suggested retail prices are current at the time of publication and are subject to change without notice.</p>
            <p class="normal">Ma Laboratories, Inc. ("Seller") hereby expressly disclaims any express or implied warranty of merchantability or fitness for a particular purpose. This disclaimer by the Seller in no way affects the terms and conditions of the manufacturer's warranty if any. Title to goods herein being purchased is retained by the seller until goods are paid for by the purchaser and at that time title passes to purchaser. If goods herein being purchased are being purchased for purposes of export, purchaser must obtain from the Federal Government certain export documentation before shipping to a foreign country, and assumes all legal liability therefor.  In addition, manufacturers' warranties for exported goods may vary or even be null and void.  Seller disclaims any legal obligation or liability with respect to any services or acts of Seller incidental to purchase and sale of any goods or merchandise.  </p>
            <p class="normal">Please <a style="color:#06f;font-style:italic;text-decoration:underline;" href="mailto:webmaster@malabs.com">contact us</a> if you have any questions regarding this disclaimer.</p>
        </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { ref } from 'vue'

export default {
  name: "Disclaimer",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Disclaimer", link: "Disclaimer"})

    return { breadcrumb_links }
  },
};
</script>

<style scoped>
.submenu-content {
    float: left;
    width: calc(100% - 300px);
    padding: 0 30px 30px;
}
p.normal {
    margin-bottom: 30px;
}

</style>